<script>
  import {
    UserMethods,
  } from "@/state/helpers";
  export default {
    data() {
      return {
        email: "",
        password: "",
        submitted: false
      };
    },
    computed: {
      // notification() {
      //   // return this.$store ? this.$store.state.notification : null;
      // }
    },
    created() {

      document.body.classList.add("auth-body-bg");
    },
    methods: {
      ...UserMethods,
      tryToLogIn() {
        this.submitted = true;
        // stop here if form is invalid
        // this.$v.$touch();
        _API_.AdminLogin({
          username: this.email,
          password: this.password
        })
          // eslint-disable-next-line no-unused-vars
          .then(res => {

            utils.ResMsg(res, () => {
              if (res.code == 200) {
                this.tryingToLogIn = false;
                this.isAuthError = false;
                this.SET_TOKEN(res.token)
                this.$router.push(
                  this.$route.query.redirectFrom || { name: "home" }
                );
              }
            })
          })
          .catch(error => {
            this.tryingToLogIn = false;
            this.authError = error ? error : "";
            this.isAuthError = true;
          })
      }
    }
  };
</script>

<template>
  <div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/logo-dark.png" height="20" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">欢迎回来</h4>
                        <p class="text-muted">登录继续使用商票易后台管理系统</p>
                      </div>

                      <div class="p-2 mt-5">
                        <form class="form-horizontal" @submit.prevent="tryToLogIn">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="email">账号</label>
                            <input type="text" v-model="email" class="form-control" id="email" placeholder="请输入账号" />
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword">密码</label>
                            <input v-model="password" type="password" class="form-control" id="userpassword"
                              placeholder="请输入密码" />
                          </div>

                          <div class="mt-4 text-center">
                            <button class="btn btn-primary w-md waves-effect waves-light" type="submit">登录</button>
                          </div>

                          <div class="mt-4 text-center">

                          </div>
                        </form>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>